/*----------------------------------------*/
/*  23. Our Client START
/*----------------------------------------*/

/* ============ Our Client version one Start ============ */

.ourclient-section-version-one {
  .wrapper {
    text-align: center;
    @media #{$xs} {
      padding-bottom: 15px;
    }
    h2 {
      font-weight: 36;
      font-weight: 700;
      color: var(--heading_color);
      text-transform: capitalize;
    }
    p {
      margin-top: 10px;
      padding: 0 200px;
      @media #{$md, $sm, $xs} {
        padding: 0;
      }
    }
  }
  .data-inner {
    margin-top: 30px;
  }
}

/* ============ Our Client version two Start ============ */

.ourclient-section-version-two {
  .data-inner-version-two {
    margin-top: 40px;
    .wrapper {
      @media #{$md, $sm, $xs} {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
      }
    }
  }
}

/* ============ Our Client version three Start ============ */
.ourclient-section-version-three {
  .data-inner-version-three {
    margin-top: 40px;
    .wrapper {
      @media #{$md, $sm, $xs} {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
      }
    }
  }
}
