/*----------------------------------------*/
/*  03. Reset Default CSS
/*----------------------------------------*/

/* ======== Reset ======== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

body {
  font-family: "Josefin Sans", sans-serif;
  font-size: 15px;
  font-weight: normal;
  color: var(--text_color);
  line-height: 26px;
}
a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Josefin Sans", sans-serif;
  color: var(--header_color);
  margin-top: 0px;
  font-weight: 600;
  line-height: 1.2;
  @include transition(0.3s);
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}
ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
p {
  font-family: "Josefin Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: var(--text_color);
  margin-bottom: 15px;
  line-height: 25px;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
  border: 0;
}

.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}

input {
  outline: none;
}

input[type="color"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

*::-moz-selection {
  background: var(--primary_color);
  color: var(--bg_white);
  text-shadow: none;
}
::-moz-selection {
  background: var(--primary_color);
  color: var(--bg_white);
  text-shadow: none;
}
::selection {
  background: var(--primary_color);
  color: var(--bg_white);
  text-shadow: none;
}

*::-moz-placeholder {
  color: var(--text_color);
  font-size: 16px;
  opacity: 1;
}
*::placeholder {
  color: var(--text_color);
  font-size: 16px;
  opacity: 1;
}

.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}
.b-radius-5 {
  border-radius: 5px;
}
.b-radius-6 {
  border-radius: 6px;
}
.b-radius-8 {
  border-radius: 8px;
}
.b-radius-10 {
  border-radius: 10px;
}

// Container

// @media (min-width: 1200px) {
//   .container {
//     max-width: 1200px;
//   }
// }
