/*----------------------------------------*/
/* 11. Bread Crumb
/*----------------------------------------*/

/* ============== Bread Crumb version One ==============*/

.bread-crumb {
  position: relative;
  z-index: 9;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  .wrapper {
    padding: 60px 0;
    text-align: center;
    h2 {
      font-size: 40px;
      font-weight: 700;
      color: var(--bg_white);
    }
    p {
      color: var(--bg_white);
      margin-top: 10px;
      font-size: 18px;
      font-weight: 600;
      span {
        color: var(--theme_color);
      }
    }
  }
}
