/*----------------------------------------*/
/*  01. Variable Implementation
/*----------------------------------------*/

/* ======== Font Includes ======== */
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

:root {
  /* ======== Color scheme ======== */
  --primary_color: #343a40;
  --theme_color: #de2021;
  --heading_color: #1e293b;
  --text_color: #6b7280;
  --bg_gray: #f8f8f8;
  --bg_white: #ffffff;
  --border_color: #e8ebee;

  /* ======== Social icon colors ======== */
  --facebook: #3b5997;
  --twitter: #1ba1f2;
  --youtube: #ed4141;
  --linkedin: #0077b5;
  --pinterest: #e60022;
  --instagram: #c231a1;
  --vimeo: #00adef;
  --twitch: #6441a3;
}

// Responsive Variables
$xxxl: "only screen and (min-width: 1601px) and (max-width: 1700px)";
$xxl: "only screen and (min-width: 1400px) and (max-width: 1600px)";
$xl: "only screen and (min-width: 1200px) and (max-width: 1399px)";
$lg: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md: "only screen and (min-width: 768px) and (max-width: 991px)";
$sm: "only screen and (min-width: 576px) and (max-width: 767px)";
$xs: "only screen and (max-width: 576px)";
$xxs: "only screen and (max-width: 380px)";

// responsive variable for wordpress admin bar
$wp-sm: "@media (max-width: 782px)";
$wp-xs: "@media (max-width: 600px)";
