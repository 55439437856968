/*----------------------------------------*/
/*  12. About-Section START
/*----------------------------------------*/

/* ============= About-section-version-one ============= */
.about-section-version-one {
  @media #{$lg} {
    padding-top: 80px;
  }
  @media #{$sm, $xs} {
    padding-top: 60px;
  }
  position: relative;
  .left-side {
    @media #{$md, $sm, $xs} {
      margin-top: 30px;
    }
    .wrapper {
      position: relative;
      .img-shape {
        img {
          border-radius: 15px;
          object-fit: cover;
          width: 100%;
        }
      }
      .pop-img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -119px;
        border: 15px solid #fff;
        border-radius: 10px;
        @media #{$lg} {
          bottom: -180px;
        }
        @media #{$xs} {
          bottom: -120px;
        }
        @media #{$xxs} {
          bottom: -190px;
        }
        img {
          @media #{$xs, $xxs} {
            width: 250px;
          }
        }

        .inner {
          position: relative;
          .vide-button {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            span {
              cursor: pointer;
              width: 80px;
              height: 80px;
              background: var(--theme_color);
              border-radius: 50%;
              color: var(--bg_white);
              transition: 0.3s;
              box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
              line-height: 120px;
              text-align: center;
              font-size: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            &:hover {
              span {
                animation: pulse 2s infinite;
              }
            }
          }
        }
      }
      .project-count {
        min-width: 220px;
        position: absolute;
        top: 0;
        right: 0;
        background: #fff;
        display: flex;
        gap: 8px;
        padding: 15px 26px;
        align-items: center;
        border-radius: 0px 15px 0px 7px;
        h3 {
          margin-bottom: 0;
          font-size: 40px;
          font-weight: 700;
          color: var(--heading_color);
          line-height: 40px;
        }
        p {
          margin-bottom: 0;
        }
      }
      .team-count {
        position: absolute;
        right: 20px;
        top: 113px;
        background: var(--primary_color);
        padding: 14px 14px;
        border-radius: 10px;
        text-align: center;
        h3 {
          font-size: 16px;
          color: #fff;
          font-weight: 700;
        }
        h2 {
          font-size: 40px;
          color: #fff;
          font-weight: 700;
          margin-bottom: 0;
          @media #{$xs} {
            font-size: 30px;
          }
          @media #{$xxs} {
            font-size: 26px;
          }
        }
        p {
          color: #fff;
          margin-bottom: 0;
        }
      }
    }
  }
  .right-side {
    padding-left: 35px;
    @media #{$md, $sm, $xs} {
      margin-top: 180px;
      padding-left: 0;
    }
    @media #{$sm, $xs} {
      margin-top: 160px;
      padding-left: 0;
    }
    @media #{$xxs} {
      margin-top: 220px;
    }
    .wrapper {
      .intro-header {
        h3 {
          font-size: 20px;
          font-weight: 600;
          color: var(--heading_color);
        }
      }
      .inner-intro {
        margin-top: 12px;
        h2 {
          font-size: 36px;
          font-weight: 700;
          color: var(--heading_color);
          text-transform: capitalize;
          @media #{$lg,$md, $sm, $xs} {
            font-size: 30px;
          }
          @media #{$md, $sm, $xs} {
            font-size: 36px;
          }
        }
        p {
          margin-top: 10px;
        }
        ul {
          padding-left: 20px;
          li {
            padding: 2px 0;
            display: flex;
            align-items: center;
            gap: 4px;
            svg {
              margin-right: 3px;
              color: var(--theme_color);
            }
            span {
              font-size: 16px;
            }
          }
        }
        hr {
          color: var(--text_color);
          margin-top: 20px;
        }
        .hub-show {
          margin-top: 20px;
          display: flex;
          gap: 25px;
          @media #{$xs} {
            display: block;
          }

          .inner-hub {
            display: flex;
            gap: 15px;
            &:first-child {
              @media #{$xs} {
                margin-bottom: 20px;
              }
            }

            p {
              margin-bottom: 0;
              font-weight: 600;
              text-transform: capitalize;
            }
          }
        }
        .btn-section {
          margin-top: 25px;
          .btn-shape-normal-outline {
            width: 175px;
          }
        }
      }
    }
    &.rtl {
      padding-right: 35px;
      @media #{$md, $sm, $xs} {
        padding-right: 0;
      }
    }
  }
  .overlay-shape-img {
    position: absolute;
    left: 0;
    top: -90px;
    z-index: -1;
    opacity: 0.5;
    @media #{$md, $sm, $xs} {
      display: none;
    }
  }
}

/* ============= About-section-version-two ============= */
.about-section-version-two {
  .left-side {
    display: flex;
    align-items: center;
    height: 100%;
    @media #{$md, $sm, $xs} {
      justify-content: center;
    }
  }
  .right-side {
    padding-left: 10px;
    @media #{$md, $sm, $xs} {
      margin-top: 40px;
      padding-left: 0;
    }
    .wrapper {
      .intro {
        h3 {
          font-size: 20px;
          font-weight: 600;
          position: relative;
          padding-left: 55px;
          &::after {
            position: absolute;
            content: "";
            left: 0;
            bottom: 8px;
            width: 50px;
            height: 4px;
            background: var(--theme_color);
            border-radius: 5px;
          }
          &.rtl {
            padding-left: 0;
            padding-right: 55px;
            &::after {
              position: absolute;
              content: "";
              left: auto;
              right: 0;
              bottom: 8px;
              width: 50px;
              height: 4px;
              background: var(--theme_color);
              border-radius: 5px;
            }
          }
        }
        h2 {
          margin-top: 26px;
          font-size: 36px;
          font-weight: 700;
          color: var(--heading_color);
          text-transform: capitalize;
          @media #{$lg} {
            font-size: 30px;
          }
        }
        p {
          margin-top: 16px;
        }
      }
      .list-data {
        margin-top: 16px;
        ul {
          padding-left: 20px;
          li {
            padding: 2px 0;
            display: flex;
            gap: 2px;
            align-items: start;
            svg {
              margin-top: 8px;
              font-size: 8px;
              color: var(--theme_color);
            }
            span {
              padding-left: 6px;
            }
          }
        }
        .btn-section {
          margin-top: 16px;
          display: flex;
          justify-content: flex-start;
          .btn-shape-normal-outline {
            width: 152px;
          }
        }
      }
    }
  }
}

/* ============= About-section-version-three ============= */
.about-section-version-three {
  margin-top: 160px;
  position: relative;
  @media #{$md, $sm, $xs} {
    padding-bottom: 180px;
  }
  .left-side {
    margin-top: 20px;
    padding-right: 55px;
    @media #{$md, $sm, $xs} {
      margin-top: 130px;
      padding-right: 0;
      padding-bottom: 80px;
    }
    @media #{$xs} {
      padding-bottom: 110px;
    }
    .wrapper {
      .intro-header {
        h3 {
          position: relative;
          padding-left: 50px;
          font-size: 20px;
          font-weight: 600;
          &::after {
            position: absolute;
            content: "";
            left: 0;
            bottom: 6px;
            width: 45px;
            height: 3px;
            border-radius: 5px;
            background: var(--theme_color);
          }
          &.rtl {
            &::after {
              left: auto;
              right: 0;
            }
          }
        }
      }
      .inner-intro {
        margin-top: 20px;
        h2 {
          font-size: 45px;
          font-weight: 700;
          color: var(--heading_color);
          text-transform: capitalize;
          @media #{$xs} {
            font-size: 36px;
          }
        }
        p {
          margin: 0;
          margin-top: 20px;
        }
        ul {
          margin-top: 20px;
          padding-left: 20px;
          li {
            padding: 5px 0;
            display: flex;
            align-items: start;
            gap: 5px;

            svg {
              margin-top: 5px;
              margin-right: 3px;
              color: var(--theme_color);
            }
            span {
              color: var(--heading_color);
              font-weight: 600;
              font-size: 16px;
            }
          }
        }
        hr {
          color: var(--text_color);
          margin-top: 20px;
        }
        .hub-show {
          margin-top: 30px;
          display: flex;
          gap: 35px;
          @media #{$xs} {
            padding-left: 20px;
            display: block;
          }
          .inner-hub {
            @media #{$xs} {
              margin-bottom: 25px;
            }
            h2 {
              font-size: 42px;
              font-weight: 700;
              color: var(--theme_color);
              margin-bottom: 0;
              @media #{$xs} {
                font-size: 55px;
              }
            }
            P {
              font-weight: 600;
              margin: 0;
            }
          }
        }
        .btn-section {
          margin-top: 25px;
          .btn-shape-normal-outline {
            width: 175px;
          }
        }
      }
    }
  }
  .right-side {
    .wrapper {
      position: relative;
      .img-shape {
        img {
          border-radius: 15px;
          object-fit: cover;
          width: 100%;
        }
      }
      .top-img {
        position: absolute;
        left: -50px;
        border: 12px solid var(--bg_white);
        top: -50px;
        z-index: 9;
        @media #{$md, $sm, $xs} {
          left: 0;
        }
        @media #{$xs} {
          img {
            width: 260px;
          }
        }
      }
      .mid-img {
        position: relative;
        @media #{$md, $sm, $xs} {
          width: 100%;
          height: 550px;
          display: flex;
          justify-content: center;
        }
        &::after {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: #33333363;
          z-index: 0;
        }
      }
      .pop-img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -119px;
        border: 12px solid #fff;
        .inner {
          position: relative;
          .vide-button {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            span {
              cursor: pointer;
              width: 80px;
              height: 80px;
              background: var(--theme_color);
              border-radius: 50%;
              color: var(--bg_white);
              transition: 0.3s;
              box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
              line-height: 120px;
              text-align: center;
              font-size: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            &:hover {
              span {
                animation: pulse 2s infinite;
              }
            }
          }
        }
      }
    }
  }

  .overlay-shape-img {
    position: absolute;
    left: 0;
    top: -53px;
    z-index: -1;
    opacity: 0.5;
  }
}
