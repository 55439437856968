/*----------------------------------------*/
/*  06. Universal CSS
/*----------------------------------------*/

.theme-color {
  color: var(--theme_color);
}

.bg-gray {
  background: var(--bg_gray);
}
.bg-white {
  background: var(--bg_white);
}

/* ================ Box shadow ================ */

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}
.shadow {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}
.shadow-md {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.shadow-lg {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}
.shadow-2xl {
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

/* ================ preloader ================ */
.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swapping-squares-spinner,
.swapping-squares-spinner * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.swapping-squares-spinner {
  height: 65px;
  width: 65px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.swapping-squares-spinner .square {
  height: calc(65px * 0.25 / 1.3);
  width: calc(65px * 0.25 / 1.3);
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  border: calc(65px * 0.04 / 1.3) solid var(--theme_color);
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.swapping-squares-spinner .square:nth-child(1) {
  -webkit-animation-name: swapping-squares-animation-child-1;
  animation-name: swapping-squares-animation-child-1;
  -webkit-animation-delay: 500ms;
  animation-delay: 500ms;
}

.swapping-squares-spinner .square:nth-child(2) {
  -webkit-animation-name: swapping-squares-animation-child-2;
  animation-name: swapping-squares-animation-child-2;
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}

.swapping-squares-spinner .square:nth-child(3) {
  -webkit-animation-name: swapping-squares-animation-child-3;
  animation-name: swapping-squares-animation-child-3;
  -webkit-animation-delay: 500ms;
  animation-delay: 500ms;
}

.swapping-squares-spinner .square:nth-child(4) {
  -webkit-animation-name: swapping-squares-animation-child-4;
  animation-name: swapping-squares-animation-child-4;
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}

@-webkit-keyframes swapping-squares-animation-child-1 {
  50% {
    -webkit-transform: translate(150%, 150%) scale(2, 2);
    transform: translate(150%, 150%) scale(2, 2);
  }
}

@keyframes swapping-squares-animation-child-1 {
  50% {
    -webkit-transform: translate(150%, 150%) scale(2, 2);
    transform: translate(150%, 150%) scale(2, 2);
  }
}

@-webkit-keyframes swapping-squares-animation-child-2 {
  50% {
    -webkit-transform: translate(-150%, 150%) scale(2, 2);
    transform: translate(-150%, 150%) scale(2, 2);
  }
}

@keyframes swapping-squares-animation-child-2 {
  50% {
    -webkit-transform: translate(-150%, 150%) scale(2, 2);
    transform: translate(-150%, 150%) scale(2, 2);
  }
}

@-webkit-keyframes swapping-squares-animation-child-3 {
  50% {
    -webkit-transform: translate(-150%, -150%) scale(2, 2);
    transform: translate(-150%, -150%) scale(2, 2);
  }
}

@keyframes swapping-squares-animation-child-3 {
  50% {
    -webkit-transform: translate(-150%, -150%) scale(2, 2);
    transform: translate(-150%, -150%) scale(2, 2);
  }
}

@-webkit-keyframes swapping-squares-animation-child-4 {
  50% {
    -webkit-transform: translate(150%, -150%) scale(2, 2);
    transform: translate(150%, -150%) scale(2, 2);
  }
}

@keyframes swapping-squares-animation-child-4 {
  50% {
    -webkit-transform: translate(150%, -150%) scale(2, 2);
    transform: translate(150%, -150%) scale(2, 2);
  }
}
.example {
  display: -ms-grid;
  display: grid;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(white),
    to(black)
  );
  background: -o-linear-gradient(top, white, black);
  background: linear-gradient(to bottom, white, black);
}
