/*----------------------------------------*/
/*  09. Footer Section START
/*----------------------------------------*/

/* ================ Footer Section version one Start ================*/

.footer-section-version-one {
  background: var(--primary_color);
  position: relative;
  @media #{$md, $sm, $xs} {
    padding-top: 40px;
    margin-top: 0;
  }
  .footer-top {
    position: absolute;
    top: -90px;
    background: var(--theme_color);
    width: 850px;
    left: 50%;
    transform: translateX(-50%);
    padding: 50px 30px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    @media #{$md, $sm, $xs} {
      display: none;
    }
    .wrapper-left {
      display: flex;
      align-items: center;
      h2 {
        font-size: 30px;
        font-weight: 700;
        color: var(--bg_white);
        line-height: 40px;
        margin-bottom: 0;
      }
    }
    .wrapper-right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      .btn-shape-normal-outline {
        background: var(--bg_white);
        color: var(--text_color);
        width: 152px;
        font-weight: 700;
        padding: 15px 10px;
      }
    }
  }
  .footer-middle {
    margin-top: 60px;
    .wrapper-one {
      padding-right: 30px;
      @media #{$md, $sm, $xs} {
        margin-bottom: 40px;
      }
      .img-file {
      }
      .text-file {
        margin-top: 16px;
        h3 {
          font-size: 22px;
          font-weight: 600;
          color: var(--bg_white);
          position: relative;
          display: inline-block;
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -5px;
            background: var(--text_color);
          }
        }
        p {
          font-size: 16px;
          margin-top: 16px;
          color: var(--bg_white);
          font-weight: normal;
          text-align: justify;
        }
      }
      .social-icon {
        margin-top: 5px;
        ul {
          display: flex;
          gap: 10px;
          li {
            a {
              padding: 2px;
              font-size: 20px;

              @media #{$sm, $xs} {
                padding: 4px;
                font-size: 30px;
              }

              svg {
                transition: all 0.2s linear;
                color: var(--bg_white);
              }
              &:hover svg {
                color: var(--theme_color);
              }
            }
          }
        }
      }
    }
    .wrapper-two {
      @media #{$md, $sm, $xs} {
        margin-bottom: 40px;
      }
      .text-file {
        h3 {
          font-size: 18px;
          font-weight: 600;
          color: var(--bg_white);
          position: relative;
          display: inline-block;
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -5px;
            background: var(--text_color);
          }
        }
      }
      .list-items {
        ul {
          margin-top: 15px;
          li {
            color: var(--bg_white);
            padding: 2px 0;
            transition: all 0.2s linear;
            &:hover {
              color: var(--theme_color);
            }
          }
        }
      }
    }
    .wrapper-three {
      @media #{$md, $sm, $xs} {
        margin-bottom: 40px;
      }
      .text-file {
        h3 {
          font-size: 18px;
          font-weight: 600;
          color: var(--bg_white);
          position: relative;
          display: inline-block;
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -5px;
            background: var(--text_color);
          }
        }
      }
      .items {
        padding-right: 15px;
        margin-top: 15px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 8px;
        grid-row-gap: 8px;
        @media #{$md, $sm, $xs} {
          grid-template-rows: repeat(2, 1fr);
        }
        img {
          border-radius: 5px;
        }
      }
    }
    .wrapper-four {
      .text-file {
        h3 {
          font-size: 18px;
          font-weight: 600;
          color: var(--bg_white);
          position: relative;
          display: inline-block;
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -5px;
            background: var(--text_color);
          }
        }
      }
      .items {
        margin-top: 15px;
        ul {
          li {
            display: flex;
            gap: 8px;
            padding: 3px 0;
            i {
              color: var(--theme_color);
            }
            span {
              color: var(--bg_white);
            }
          }
        }
      }
    }
  }
  .footer-bottom {
    margin-top: 20px;
    text-align: center;
    p {
      font-size: 14px;
      color: var(--bg_white);
    }
  }
}

/* ================ Footer Section version two Start ================*/

.footer-section-version-two {
  background: var(--primary_color);
  position: relative;
  padding-top: 30px;
  @media #{$md, $sm, $xs} {
    margin-top: 0;
  }
  .footer-top {
    margin-top: 60px;

    .wrapper-one {
      padding-right: 30px;
      @media #{$md, $sm, $xs} {
        margin-bottom: 40px;
      }
      .text-file {
        margin-top: 16px;
        h3 {
          font-size: 22px;
          font-weight: 600;
          color: var(--bg_white);
          position: relative;
          display: inline-block;
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -5px;
            background: var(--text_color);
          }
        }
        p {
          font-size: 16px;
          margin-top: 16px;
          color: var(--bg_white);
          font-weight: normal;
          text-align: justify;
        }
      }
      .social-icon {
        margin-top: 5px;
        ul {
          display: flex;
          gap: 10px;
          li {
            a {
              padding: 2px;
              font-size: 20px;

              i {
                transition: all 0.2s linear;
              }
              &:hover .fa-facebook-f {
                color: var(--facebook);
              }
              &:hover .fa-twitter {
                color: var(--twitter);
              }
              &:hover .fa-youtube {
                color: var(--youtube);
              }
              &:hover .fa-linkedin {
                color: var(--linkedin);
              }
            }
          }
        }
      }
    }
    .wrapper-two {
      @media #{$md, $sm, $xs} {
        margin-bottom: 40px;
      }
      .text-file {
        h3 {
          font-size: 18px;
          font-weight: 600;
          color: var(--bg_white);
          position: relative;
          display: inline-block;
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -5px;
            background: var(--text_color);
          }
        }
      }
      .list-items {
        ul {
          margin-top: 15px;
          li {
            color: var(--bg_white);
            padding: 2px 0;
            transition: all 0.2s linear;
            position: relative;
            &::after {
              position: absolute;
              content: "";
              left: 0;
              bottom: 12px;
              width: 0;
              height: 2px;
              border-radius: 5px;
              background: var(--bg_white);
              transition: all 0.2s linear;
            }
            &:hover {
              padding-left: 15px;
              color: var(--theme_color);
              &::after {
                width: 12px;
              }
            }
          }
          &.rtl {
            li {
              &::after {
                left: auto;
                right: 0;
              }
              &:hover {
                padding-left: 0;
                padding-right: 17px;
              }
            }
          }
        }
      }
    }
    .wrapper-three {
      @media #{$md, $sm, $xs} {
        margin-bottom: 40px;
      }
      .text-file {
        h3 {
          font-size: 18px;
          font-weight: 600;
          color: var(--bg_white);
          position: relative;
          display: inline-block;
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -5px;
            background: var(--text_color);
          }
        }
      }
      .input-section {
        padding-right: 15px;
        margin-top: 15px;
        input {
          display: block;
          width: 100%;
          height: 44px;
          border-radius: 5px;
          border: none;
          padding: 0 10px;
          display: flex;
          align-items: center;
          &::placeholder {
            font-size: 13px;
          }
        }
        .btn-shape-normal-outline {
          padding: 8px;
          width: 120px;
          margin: 0;
          margin-top: 15px;
        }
      }
      .social-icon {
        margin-top: 15px;
        ul {
          display: flex;
          gap: 10px;
          align-items: center;
          padding-top: 2px;

          li {
            padding: 2px;

            svg {
              font-size: 20px;
              transition: all 0.2s linear;
              color: var(--bg_white);
            }
            &:hover svg {
              color: var(--theme_color);
            }
          }
        }
      }
    }
    .wrapper-four {
      .text-file {
        h3 {
          font-size: 18px;
          font-weight: 600;
          color: var(--bg_white);
          position: relative;
          display: inline-block;
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -5px;
            background: var(--text_color);
          }
        }
      }
      .items {
        margin-top: 15px;
        ul {
          li {
            display: flex;
            gap: 8px;
            padding: 3px 0;
            i {
              color: var(--bg_white);
            }
            span {
              color: var(--bg_white);
            }
          }
        }
      }
    }
  }
  .footer-bottom {
    margin-top: 20px;
    text-align: center;

    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 5px;
      @media #{$sm, $xs} {
        display: block;
      }
      p {
        font-size: 14px;
        color: var(--bg_white);
        margin: 0;
        @media #{$sm, $xs} {
          text-align: start;
        }
      }
      ul {
        display: flex;
        align-items: center;
        gap: 10px;
        @media #{$sm, $xs} {
          margin-top: 8px;
          text-align: start;
        }
        li {
          a {
            padding: 2px;
            color: var(--bg_white);
            transition: all 0.2s linear;
            &:hover {
              color: var(--theme_color);
            }
          }
        }
      }
    }
  }
}

/* ================ Footer Section version three Start ================*/

.footer-section-version-three {
  background: var(--primary_color);
  position: relative;
  padding-top: 30px;
  .footer-top {
    margin-top: 60px;
    .wrapper-one {
      padding-right: 30px;
      @media #{$md, $sm, $xs} {
        margin-bottom: 40px;
      }
      .text-file {
        margin-top: 16px;
        h3 {
          font-size: 22px;
          font-weight: 600;
          color: var(--bg_white);
          position: relative;
          display: inline-block;
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -5px;
            background: var(--text_color);
          }
        }
        p {
          font-size: 16px;
          margin-top: 16px;
          color: var(--bg_white);
          font-weight: normal;
          text-align: justify;
        }
      }
      .social-icon {
        margin-top: 5px;
        ul {
          display: flex;
          gap: 10px;
          li {
            a {
              padding: 2px;
              font-size: 20px;
              color: var(--bg_white);

              svg {
                transition: all 0.2s linear;
              }
              &:hover svg {
                color: var(--theme_color);
              }
            }
          }
        }
      }
    }
    .wrapper-two {
      @media #{$md, $sm, $xs} {
        margin-bottom: 40px;
      }
      .text-file {
        h3 {
          font-size: 18px;
          font-weight: 600;
          color: var(--bg_white);
          position: relative;
          display: inline-block;
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -5px;
            background: var(--text_color);
          }
        }
      }
      .list-items {
        ul {
          margin-top: 15px;
          li {
            color: var(--bg_white);
            padding: 2px 0;
            transition: all 0.2s linear;
            svg {
              font-size: 8px;
              margin-right: 6px;
            }
            &:hover {
              color: var(--theme_color);
            }
          }
        }
      }
    }
    .wrapper-three {
      @media #{$md, $sm, $xs} {
        margin-bottom: 20px;
      }
      .text-file {
        h3 {
          font-size: 18px;
          font-weight: 600;
          color: var(--bg_white);
          position: relative;
          display: inline-block;
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -5px;
            background: var(--text_color);
          }
        }
      }
      .items {
        padding-right: 15px;
        margin-top: 15px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 8px;
        grid-row-gap: 8px;
        @media #{$md, $sm, $xs} {
          grid-template-rows: repeat(2, 1fr);
        }
        img {
          border-radius: 5px;
        }
      }
    }
    .wrapper-four {
      padding-left: 10px;
      @media #{$md, $sm, $xs} {
        padding-left: 0;
        margin-bottom: 40px;
      }
      .text-file {
        h3 {
          font-size: 18px;
          font-weight: 600;
          color: var(--bg_white);
          position: relative;
          display: inline-block;
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -5px;
            background: var(--text_color);
          }
        }
      }
      .items {
        margin-top: 15px;
        ul {
          li {
            display: flex;
            gap: 8px;
            padding: 3px 0;
            i {
              color: var(--theme_color);
            }
            span {
              color: var(--bg_white);
            }
          }
        }
      }
    }
  }
  .footer-bottom {
    margin-top: 20px;
    text-align: center;
    p {
      font-size: 14px;
      color: var(--bg_white);
    }
  }
}
