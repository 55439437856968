/*----------------------------------------*/
/*  05. Button Design START
/*----------------------------------------*/

/* ======= Button Shape One ======= */
.btn-shape-normal-outline {
  display: inline-block;
  border-radius: 4px;
  background-color: var(--theme_color);
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 17px;
  padding: 16px;
  width: auto;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;

  span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    &:after {
      content: "»";
      position: absolute;
      opacity: 0;
      top: 0;
      right: -15px;
      -webkit-transition: 0.5s;
      transition: 0.5s;
    }
  }
  &:hover span {
    padding-right: 15px;
  }
  &:hover span:after {
    opacity: 1;
    right: 0;
  }
  &.purchase {
    i {
      color: var(--bg_white) !important;
    }
  }
}
